@import '../../../style/abstract/variables';
@import '../../../style/abstract/media';
@import '../../../style/abstract/a11y';
@import '../../../style/abstract/button';
@import '../../../style/abstract/loader';
@import '../../../style/abstract/icons';
@import '../../../style/abstract/parts';
@import '../../../style/abstract/font';
@import '../../../style/abstract/cms';

.BannerFullwidth {
    --banner-height: 500px;

    @include banner-fullwidth;

    @include mobile {
        --banner-height: 400px;
    }

    &-Title {
        &:not(:last-child) {
            margin-block-end: 8px;
        }
    }

    &-Subtitle {
        @include font(24, 30, 600, false, 1);

        margin-block-end: 20px;

        @include mobile {
            @include font(18, 22);

            text-transform: uppercase;
        }
    }

    &-CTA {
        @include mobile {
            margin-inline-end: auto;
        }
    }
}
