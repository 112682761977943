@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/a11y';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/font';
@import '../../style/abstract/cms';

/* stylelint-disable declaration-no-important */

:root {
    --footer-nav-height: 90px;
    --footer-content-height: 95px;
    --overlay-desktop-border-color: $selection;
}

.Footer {
    $block: &;

    letter-spacing: 1px;
    background-color: $white;
    
    [data-content-type="row"] {
        padding-inline: calc((100% - #{$content-max-width}) / 2);

        @include desktop {
            margin-inline: auto;
        }

        @include tablet {
            padding-inline: calc((100% - #{$content-max-width}) / 2);
        }
        
        @include narrow-mobile {
            padding-inline: 20px;
        }

        @include mobile {
            padding-inline: calc((100% - 230px) / 2);
        }
    }
    
    .CmsBlock-Wrapper {
        width: 100%;
        padding-block-start: 106px;

        @include mobile {
            padding-block-start: 0;
        }
    }
    
    
    .pagebuilder-column {
        flex: 20%;
        align-items: left;
        
        @include tablet {
            flex: 45%;
        }
        
        @include mobile {
            padding-block-end: $gutter;
        }

        &-group {
            flex-wrap: wrap;
            
            @include mobile {
                flex-wrap: wrap;
                flex-direction: row !important;
            }
        }

        h4 {
            margin-block-end: 24px;
        }
        
        a {
            text-decoration: none;
            font-weight: 400;
        }
        
        p {
            text-align: start;
        }  
    }


    &-LogoWrapper {
        width: 100%;
        text-align: center;
        
        .Image {
            width: 235px;
            height: 50px;
            
            &_ratio {
                &_square {
                    padding-block-end: 0;
                }
            }
        }
        
        @include mobile {
            padding-block: 40px;
        }
    }

    &-CopyrightContentWrapper {
        height: fit-content;
    }

    &-CopyrightContent {
        padding-block-start: 31px;
        padding-block-end: 23px;

        @include mobile {
            padding-block-start: $gutter;
        }

        span {
            @include font(16, 25, 400, $black, 1);

            @include mobile {
                @include font(12, 20, 400);
            }
            
            @include narrow-mobile {
                font-size: 10px;
            }
        }
    }
    
    &-SocialOverlayWrapper {
        position: static;
    }

    .Overlay:not(.Overlay_isStatic) {
        position: absolute;
        padding-block-start: 10px;
        height: 137px;
        inset-block-start: calc(100px * -1);
        overflow: unset;
        width: fit-content;
        min-width: 352px;
        
        @include mobile {
            min-width: 0;
            width: calc(100vw - 40px);
            inset-block-start: -157px;
            inset-inline-start: 0;
        }

        .pagebuilder-column, p, .ContentWrapper {
            @include mobile {
                padding: 0;
                margin: 0;
            }
        }
        
        .Overlay-CloseBtn {
            margin-inline-start: calc(100% - 6px);
            
            svg {
                width: 12px;
                height: 12px;
            }
        }
    }

    
    &-Overlay {        
        transform: translate(-50%, -40%);

        @include mobile {
            transform: none;
        }

        &_isVisible {
            width: 100px;
            inset-block-start: 0;
            padding-inline: $gutter;
            height: 110px;
            border: 1px outset;
            
            .CmsBlock-Wrapper {
                margin: 0;
                padding: 0;
                height: var(--footer-content-height);
                width: 100%;
                
                [data-content-type="row"] {
                    padding-inline: 0;
                }

                .pagebuilder-mobile-only {
                    min-height: 70px;
                }
            }
    
            @include mobile {
                inset-block-start: var(--header-total-height);
                height: calc(100% - var(--header-total-height));
            }
        }

        .Overlay-Content {
            [data-background-type="image"] {
                @include mobile {
                    display: inline;
                    flex-direction: inherit;
                }
            }
        }
        
        .pagebuilder-column {
            align-items: center;
            justify-content: center;
            min-width: max-content !important;
            
            @include mobile {
                padding: 0;
                width: fit-content;
            }

            &-group {
                flex-wrap: nowrap;
                margin: 0;
                gap: 30px;

                @include mobile {
                    flex-direction: row;
                    gap: 18px;
                }

                @include narrow-mobile {
                    column-gap: 5%;
                }
            }
            
            figure, img {
                width: 70px;
                height: 70px;
                margin: 0;
                border-radius: 50%;
            } 

            p, [data-content-type="text"] {
                text-transform: uppercase;
                text-align: center;
            }

            p {
                margin: 10px 0 0;

                @include font(10, 20, 600, false, 1);
            }
        }
    }

    &-SocialsWrapper {
        display: flex;
        justify-content: center;
        margin-block-end: 56px;

        @include mobile {
            padding-block-end: 0px;
            margin-block-end: 0px;
        }

        #{$block}-SocialsBlock {
            padding-inline: 10px;

            @include mobile {
                position: static;
            }
            
            svg {
                width: $gutter;
                height: $gutter;
            }
        }
    } 

    &-Links {
        &,
        #{$block}-CmsBlockWrapper,
        .CmsBlock-Wrapper,
        [data-content-type="row"],
        [data-background-type="image"],
        .pagebuilder-column {
            height: calc(var(--footer-content-height) - 55px);
            padding: 0;
        }
        
        .Footer-Content {
            min-height: calc(var(--footer-content-height) - 55px);
        }
        
        .CmsBlock-Wrapper {
            background-color: $black;
            padding: 0;
            
            [data-background-type="image"] {
                flex-direction: row !important;
                justify-content: center !important;
            }
        }
        
        .pagebuilder-column {
            flex: 20% 1;
            padding-block-start: 2%;
            
            &-group, p {
                margin: 0;
                align-items: center;
                column-gap: 20px;
            }
            
            p {
                min-width: max-content;
            }
            
            a {
                color: $white;
                line-height: 25px;
                
                @include font(12, 20, 400);

                @include narrow-mobile {
                    font-size: 10px;
                }
            }
        }
    }
}
