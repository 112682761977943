@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/a11y';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/font';
@import '../../style/abstract/cms';

.FieldSelect {
    @include focus-outline;

    &-Select {
        height: var(--input-height);
        padding: var(--input-padding);
    }

    &-Options {
        border: none;
        
        @include mobile {
            border: none;
        }
    }

    &-Option {
        &:hover,
        &:focus-visible {
            background-color: $selection;
        }
    }
}
