@import '../../../style/abstract/variables';
@import '../../../style/abstract/media';
@import '../../../style/abstract/a11y';
@import '../../../style/abstract/button';
@import '../../../style/abstract/loader';
@import '../../../style/abstract/icons';
@import '../../../style/abstract/parts';
@import '../../../style/abstract/font';
@import '../../../style/abstract/cms';

.FooterColumns {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    column-gap: var(--cms-page-spacing);
    $block: &;


    @include mobile {
        display: inline-block;
        margin: auto;
    }

    @include tablet {
        grid-template-columns: repeat(2, minmax(200px, 1fr));
        margin-inline: 10%;
    }

    @include narrow-desktop {
        column-gap: 2%;
    }

    &-Column {
        margin-block-end: 30px;

        &_header {
            text-transform: uppercase;
            margin-block-end: 24px;

            @include font(20, 25, 600);
        }

        button {
            display: block;
            width: 250px;
            text-align: start;
            margin-inline: auto;

            @include font(15, 20, 600);

            .ChevronThickIcon {
                float: inline-end;
            }
        }

        div {
            margin-inline-start: 5px;
        }

        &_contact {
            div #{$block}-Column_header {
                text-transform: none;
                margin-block-end: 10px;

                @include font(16, 25, 600);
            }
        }

        p, a {
            margin-block-end: 20px;

            @include mobile {
                @include font(14, 25, 400)
            }
        }
    }
}
