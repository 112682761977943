@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/a11y';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/font';
@import '../../style/abstract/cms';

.CarouselScrollItem {
    width: auto;
    height: 100%;
    filter: none;

    + .CarouselScrollItem {
        margin-inline-start: 0;
    }

    .ProductGalleryThumbnailImage {
        height: 0;
        padding: 0 0 60%;
    }
}

