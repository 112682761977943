@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/a11y';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';
@import '../../../../../src/style/abstract/font';
@import '../../../../../src/style/abstract/cms';

.BlogPostCard {
    &-ContentWrapper {
        .Image {
            height: 285px;
            padding-block-end: 0;
            margin-block-end: 10px;

            @include mobile {
                height: 170px;
            }

            &-Image {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }

    &-Date {
        @include font(16, 20, 600, $selection, 1);

        display: block;
        margin-block-end: 10px;

        @include mobile {
            @include font(14, 18);
        }
    }

    &-ShortContent {
        margin-block-end: 10px;

        div[data-content-type="row"],
        p {
            margin: 0;
        }
        
        p {
            @include font(16, 25);
        }
    }

    &-ReadMoreButton {
        @include font(16, 20, 600);

        text-transform: uppercase;
        display: flex;
        align-items: center;

        @include mobile {
            @include font(14, 18);
        }

        @include desktop {
            &:hover {
                .ChevronThickIcon {
                    margin-inline-start: 15px;
                }
            }
        }

        .ChevronThickIcon {
            margin-inline-start: 10px;
            transition: margin $rapid cubic-bezier(.56,.25,.4,1.99);
        }
    }

    a {
        text-decoration: none;
    }
}
