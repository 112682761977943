@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/a11y';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/font';
@import '../../style/abstract/cms';

.RetailerApplicationSignIn {
    display: grid;
    grid-template-columns: minmax(auto, 450px);
    padding-inline: 20px;

    .Form {
        text-align: start;
        width: 100%;

        .FieldForm-Fields {
            padding-block-start: 20px;
            padding-block-end: 35px;

            * {
                margin-inline: 0;
            }
        }

        input {
            max-height: 60px;
        }

        .Button {
            width: 100%;
            max-height: 60px;

            &:first-of-type {
                margin-block-end: 20px;
            }

            &:last-of-type {
                background-color: transparent;
            }

            &_text {
                @include font(16, 20, 600, $default-primary-base-color);

                text-transform: capitalize;
                text-decoration: underline;
            }
        }
    }
}
