@import '../../../style/abstract/variables';
@import '../../../style/abstract/media';
@import '../../../style/abstract/a11y';
@import '../../../style/abstract/button';
@import '../../../style/abstract/loader';
@import '../../../style/abstract/icons';
@import '../../../style/abstract/parts';
@import '../../../style/abstract/font';
@import '../../../style/abstract/cms';

.ChevronDoubleIcon {
    &_direction {
        &_left {
            transform: rotate(0deg);
        }

        &_right {
            transform: rotate(180deg);
        }

        &_bottom {
            transform: rotate(-90deg);
        }

        &_top {
            transform: rotate(90deg);
        }
    }
}
