@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/a11y';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/font';
@import '../../style/abstract/cms';

.SearchPage {
    @include mobile {
        margin-block-start: 30px;

        &-CloseIconWrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: $button-min-size;
            height: $button-min-size;
            margin-inline-start: auto;
        }

        &-Results {
            margin-block: 20px;
        }

        &-NoResults {
            @include font(16, 24, 400, false, 1);

            padding-block-start: 30px;
        }
    }

    &-Heading {
        text-transform: uppercase;
        font-family: $font-source-sans-pro;

        @include font(32, 40, 600);

        span {
            text-transform: none;
        }
    }
}
