@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/a11y';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/font';
@import '../../style/abstract/cms';

.TopMenu {
    background-color: $default-primary-base-color;
    
    &-Wrapper {
        height: var(--header-top-menu-height);
        max-width: var(--header-content-width);
        padding-inline: var(--header-gutter);
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;

        > * {
            flex: 1;
        }
    }

    &-Actions {
        margin-inline-end: auto;
        display: flex;
    }

    &-Button,
    &-Link {
        @include font(10, 12, 600, false, 2);
        --button-min-height: var(--header-top-menu-height);
        --button-min-width: auto;
        --button-padding: 15px;

        max-width: fit-content;
        font-family: $font-source-sans-pro;

        &:hover {
            transform: scale(1);
        }
    }

    &-Links {
        margin-inline-start: auto;
        display: flex;
        justify-content: flex-end;

        .TopMenu-Link {
            &:last-of-type {
                padding-inline-end: 0;
            }
        }
    }

    &-Link {
        display: flex;
        align-items: center;

        &:hover {
            background-color: $default-primary-base-color;
            text-decoration: underline;
        }
    }

    .Button {
        &:focus-visible {
            outline: 1px solid $white;
            outline-offset: -2px;
        }

        &_secondary {
            cursor: default;

            &:focus-visible {
                outline-color: $text;
            }

            &:hover {
                background-color: $default-secondary-base-color;

                &::before {
                    width: 0%;
                }
            }
        }
    }
}
