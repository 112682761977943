@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/a11y';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';
@import '../../../../../src/style/abstract/font';
@import '../../../../../src/style/abstract/cms';

.PostsDetails {
    max-width: $content-max-width;
    margin: auto;
    padding-block-end: 100px;

    @include mobile {
        padding-block-end: 40px;
    }

    &-Title {
        text-align: center;
        padding-block: 50px;

        @include mobile {
            padding-block: $gutter;
        }
    }

    &-Content {   
        [data-content-type="row"] {
            padding-block-end: 50px;
            
            @include mobile {
                padding-block-end: $gutter;
            }
        }      

        .pagebuilder {
            &-column {
                figure {
                    @include mobile {
                        height: 300px;
                    }
                }
            }
            
            &-mobile-only {
                min-height: 300px;
            }
        }
        
        .ProductList {
            &-Actions {
                flex-direction: row-reverse;
            }
        }
    }

    &-ShortContent {
        text-align: center;
        margin-block-end: 50px;
        margin-inline: auto;
        max-width: 750px;

        @include mobile {
            margin-block: $gutter;
        }
    }
}
