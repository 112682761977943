@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/a11y';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/font';
@import '../../style/abstract/cms';

.BurgerMenuOverlay {
    inset-block-start: calc(var(--header-nav-height) - 42px);
    inset-inline-end: -10px;

    .Menu {
        &-MenuWrapper {
            @include ultra-narrow-desktop {
                display: block;
                padding: 15px 10px;
            }
        }

        &-ItemList {
            @include desktop {
                flex-direction: column;
            }
        }
        
        &-Item {
            width: 100%;
            
            & > a {
                padding: 10px 0;
            }

            @include desktop {
                &:first-of-type {
                    margin-inline-start: 20px;
                }
            }
        }

        &-Sub {
            &CategoriesWrapper {
                inset-inline: auto calc(100% + 10px);
                inset-block-start: 0;
                padding: 0;
            }

            &ItemWrapper {
                .Menu-Link {
                    padding: 0px 15px;
                }
            }
        }
    }
}
