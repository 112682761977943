@import '../../../style/abstract/variables';
@import '../../../style/abstract/media';
@import '../../../style/abstract/a11y';
@import '../../../style/abstract/button';
@import '../../../style/abstract/loader';
@import '../../../style/abstract/icons';
@import '../../../style/abstract/parts';
@import '../../../style/abstract/font';
@import '../../../style/abstract/cms';

.ShowMe {
    text-align: center;
    max-width: 926px;
    margin: auto;

    &-Filters {
        --input-height: 47px;
        --input-padding: 12px 20px;

        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 16px;
        margin-block-start: 30px;
        align-items: center;

        .Field {
            &-Wrapper {
                grid-column: auto;
                margin: 0;
            }

            &Select {
                &-Options {
                    margin-inline-start: 0;
                    text-align: start;

                    li:first-of-type {
                        display: none;
                    }
                }
            }
        }

        button {
            order: 5;
        }

        @include mobile {
            grid-template-columns: 1fr 1fr;

            button {
                width: fit-content;
                margin: auto;
                grid-column: span 2;
            }
        }
    }
}
