@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/a11y';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/font';
@import '../../style/abstract/cms';

:root {
    --cell-border: 1px solid #{$light-grey};
}

.TradeshowsForm {
    margin: 50px auto;
    display: grid;
    grid-template-columns: repeat(2, auto);
    column-gap: 50px;
    
    @include wide-desktop {
        grid-template-columns: 280px auto;
    }

    @include mobile {
        display: block;
        padding-block-start: $gutter;
        padding-block-end: 40px;
    }

    .Field {
        max-width: 100%;

        &Form {
            &-Fields {
                display: block;
                
                @include wide-desktop {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    column-gap: $gutter;
                }
            }
            
            &-Body {
                @include tablet {
                    max-width: 425px;
                }

                @include narrow-desktop {
                    max-width: 425px;
                }
            }

            &-SecondColumn {
                padding-block-start: $gutter;

                @include wide-desktop {
                    padding-block-start: 0;
                }
            }
        }

        textarea {
            width: 100%;
            height: 200px;
            overflow-y: auto;
        }
    }

    button {
        float: inline-end;

        @include mobile {
            float: inline-start;
        }
    }

    .react-datepicker {
        max-width: 280px;
        border: none;

        &, &__header {
            background-color: transparent;
            text-align: start;
            font-family: $font-source-sans-pro;
            border-bottom: 0;
            padding-block-end: 0;
        }

        &__current-month {
            margin-block-end: $gutter;

            @include font(18, 23, 600, $black, 1);
        }
        
        &__navigation--previous {
            inset-inline-start: 200px
        }
        
        &__day-names, &__week {            
            * {
                width: 40px;
                height: 40px;
                margin: 0;
                padding: 6px;
            }
        }

        &__day {
            border-right: var(--cell-border);

            &:hover {
                background-color: $selection;
                border-radius: 0;
            }

            &--selected,
            &--keyboard-selected {
                border-radius: 0;
                background-color: $black;
            }

            &--outside-month {
                color: $selection;
            }
        }

        &__month, &__day-names {
            background-color: $white;
            margin: 0;
        }
        
        &__month {
            border: var(--cell-border);
            border-bottom: none;
            border-right: none;
            
            > * {
                border-bottom: var(--cell-border);
            }
        }
    }

    &-Calendar {
        .Field-Wrapper {
            max-width: 280px;
            padding-block: $gutter;

            @include mobile {
                max-width: 100%;
            }
        }
    }

    &-Popup {
        .Popup {
            &-Content {
                width: 750px;
                padding-inline: 50px;

                >*, p {
                    max-width: fit-content;
                    margin: auto;
                    text-align: center;
                }

                h1 {
                    padding-block: $gutter;
                    font-family: $font-source-sans-pro;
                    text-align: center;

                    @include font(24, 30, 600, false, 1);

                    @include mobile {
                        @include font(18, 23);
                    }
                }

                >svg {
                    width: 100%;
                    padding-inline-start: calc(50% - 15px);
                }

                .Button {
                    margin-block-start: $gutter;
                    display: inherit;
                }
            }
        }
    }
}
