@import '../../../style/abstract/variables';
@import '../../../style/abstract/media';
@import '../../../style/abstract/a11y';
@import '../../../style/abstract/button';
@import '../../../style/abstract/loader';
@import '../../../style/abstract/icons';
@import '../../../style/abstract/parts';
@import '../../../style/abstract/font';
@import '../../../style/abstract/cms';

.BannerCampaign {
    --banner-height: 100%;
    
    @include banner-fullwidth;
    
    padding: 50px;
    
    @include mobile {
        --banner-height: 250px;

        background-position: 0% 10%;
    }

    &-ColumnGroup {
        gap: 20px;
        margin-block-end: 32px;

        .pagebuilder-column {
            &:first-of-type {
                .Pagination {
                    inset-inline-start: 0;

                    @include mobile {
                        inset-inline-start: auto;
                        inset-inline-end: 0;
                    }
                }
            }

            &:last-of-type {
                .Pagination {
                    inset-inline-end: 0;
                }
            }
        }
        
        @include mobile {
            .ContentView-Column {
                order: 1;
            }

            .CatalogProductList-Column {
                order: 2;
            }
        }
    }

    &-Title {
        @include font(70, 80, 600);

        font-family: $font-source-sans-pro;

        @include mobile {
            @include font(30, 40);
        }
    }

    &-Subtitle {
        @include font(24, 40, 600);

        @include mobile {
            @include font(18, 22);

            margin-block-end: 5px;
        }
    }

    &-CTA {
        margin-block-start: 20px;
    }
}
