@function rem($pixels, $context: $baseFontSize) {
    @if (unitless($pixels)) {
        $pixels: $pixels * 1px;
    }

    @if (unitless($context)) {
        $context: $context * 1px;
    }

    @return $pixels / $context * 1rem;
}

@mixin font($size, $lineHeight, $weight: false, $color: false, $ls: false) {
    @if $size {
        font-size: rem($size);
    }

    @if $lineHeight {
        line-height: rem($lineHeight);
    }

    @if $weight {
        font-weight: $weight;
    }

    @if $color {
        color: $color;
    }

    @if $ls {
        letter-spacing: $ls + px;
    }
}

@mixin truncate {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
