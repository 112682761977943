:root {
    --button-background: #{$default-primary-base-color};
    --button-border-radius: 0;
    --button-color: #{$white};
    --button-padding: 10px;
    --button-min-height: 47px;
    --button-min-width: 172px;
    --button-transition-background: #{$default-primary-light-color};
}

@mixin button {
    @include font(14, 18, 400, var(--button-color), 1);

    font-family: $font-source-sans-pro;
    text-transform: uppercase;
    text-decoration: none;
    padding-inline: var(--button-padding);
    background-color: var(--button-background);
    min-height: var(--button-min-height);
    min-width: var(--button-min-width);
    cursor: pointer;
    transition-duration: $rapid;
    transition-timing-function: ease-out;
    transition-property: background-color, color, border, transform;
    will-change: background-color, color, border, transform;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &::before {
        content: '';
        position: absolute;
        inset-block-end: 0;
        inset-inline-start: 0;
        width: 0%;
        height: 100%;
        background-color: var(--button-transition-background);
        z-index: -2;
        transition: width $fast;
        will-change: width;
    } 

    @include desktop {
        &:hover {
            &::before {
                width: 100%;
            }
        }
    }

    @include mobile {
        transition-duration: 100ms;

        &:active {
            transform: scale3d(0.96, 0.96, 0.96);
        }
    }

    &[disabled] {
        --button-background: #{$selection};

        pointer-events: none;
    }

    @include mobile {
        --button-min-height: 30px;
        --button-min-width: 120px;

        user-select: none;
    }
}
