@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/a11y';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/font';
@import '../../style/abstract/cms';

.Slider {
    &-Wrapper {
        transition: none;
    }
    
    &-Arrow {
        &_isNext {
            inset-inline-end: 0;
        }

        &_isPrev {
            inset-inline-start: 0;
        }

        &_isDisabled {
            pointer-events: all;
        }
    }
}
