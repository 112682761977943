@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/a11y';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/font';
@import '../../style/abstract/cms';

.BackButton {
    @include font(12, 20, 400, false, 1);

    font-family: $font-source-sans-pro;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }

    .ChevronThickIcon {
        margin-inline-end: 8px;
    }
}
