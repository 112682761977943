@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/a11y';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/font';
@import '../../style/abstract/cms';

:root {
    --popup-content-background: #{$background};
    --popup-content-padding: 50px 20px;
    --popup-min-width: 700px;
    --popup-background: #{$selection};

    @include mobile {
        --popup-min-width: 100%;
    }
}

.Popup {
    @include mobile {
        align-items: center;
    }
    
    &_isVisible {
        animation: appear $rapid forwards;
        
        @include mobile {
            inset-block-start: 0;
            inset-inline-end: 0;
            height: 100vh;
            padding: 20px;
        }
    }
    
    &-Header {
        @include mobile {
            display: block;
        }
    }
    
    &-Content {
        animation: appear $rapid forwards;
        border-radius: 0;
        overflow-y: initial;
    }

    & &-CloseBtn {
        inset-block-start: 5px;
        inset-inline-end: 5px;
        width: $button-min-size;
        height: $button-min-size;
        cursor: pointer;

        &:hover {
            .CloseIcon {
                path {
                    fill-opacity: 0.4;
                }
            }
        }

        .CloseIcon {
            width: 20px;
            height: 20px;
        }
    }
}
