@import '../../../style/abstract/variables';
@import '../../../style/abstract/media';
@import '../../../style/abstract/a11y';
@import '../../../style/abstract/button';
@import '../../../style/abstract/loader';
@import '../../../style/abstract/icons';
@import '../../../style/abstract/parts';
@import '../../../style/abstract/font';
@import '../../../style/abstract/cms';


:root {
    --video-format: 16 / 9
}

.Video {
    width: 100%;
    aspect-ratio: var(--video-format);

    @include mobile {
        aspect-ratio: unset;
    }

    &::-webkit-media-controls {
        &-timeline,
        &-time-remaining-display,
        &-current-time-display,
        &-toggle-closed-captions-button {
            display: none;
        }

        &-fullscreen-button {
            @include mobile {
                display: initial;
            }
        }
    }
}
