@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/a11y';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/font';
@import '../../style/abstract/cms';

.HeartIcon {
    will-change: stroke, fill;
    transition: stroke $rapid, fill $rapid;

    &:hover {
        stroke: $maximum-red;
        fill: $maximum-red;
    }

    &_isActive {
        fill: $maximum-red;
        stroke: $maximum-red;

        &:hover {
            fill: none;
            stroke: var(--color-black);
        }
    }
}
