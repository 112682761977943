@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/a11y';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/font';
@import '../../style/abstract/cms';

.ForgotPassword {
    &-ContainerWrapper {
        max-width: 600px;
    }

    h3, p {
        margin-block-end: 20px;
    }

    &-ErrorMessage {
        @include font(12, 20);

        color: $maximum-red;
    }
}
