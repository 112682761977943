@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/a11y';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/font';
@import '../../style/abstract/cms';

.FavouritesPage {
    padding-block: 50px;
    min-height: calc(100vh - var(--header-total-height));

    @include mobile {
        padding-block: 0px 40px
    }

    &-Title {
        margin-block-end: 20px;
    }

    &-ProductList {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(285px, 1fr));
        gap: 20px;

        &_hasFewItems {
            grid-template-columns: repeat(4, 1fr);
        }

        @include mobile {
            grid-template-columns: repeat(2, 1fr);
            gap: 10px;
        }
    }
}
