@mixin banner-fullwidth {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 50px;
    width: 100%;
    height: 100%;
    min-height: var(--banner-height);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @include mobile {
        padding: 10px;
    }
}
