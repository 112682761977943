@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/a11y';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/font';
@import '../../style/abstract/cms';

@mixin widgetLink {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    background-color: $selection;
    border-radius: 50%;

    &:last-of-type {
        margin-inline-start: 10px;
    }
}

.Pagination {
    min-height: auto;
    margin-block-start: 0;

    @include mobile {
        display: flex;
    }

    .ChevronIcon,
    .ChevronDoubleIcon {
        width: initial;
        height: initial;
        display: block;
        cursor: pointer;
    }

    &-ListItem {
        min-width: initial;

        &::before {
            display: none;
        }

        &_isWidget {
            .PaginationLink_isArrow {
                @include widgetLink();

                cursor: pointer;
                transition: background-color $rapid;
                will-change: background-color;

                &:hover {
                    background-color: rgba(0,0,0,0.1);
                }
            }
        }

        &_isDisabled {
            @include widgetLink();

            opacity: 0.5;
        }
    }

    &-Numbers {
        display: flex;
        align-items: center;
        margin: 0 10px;
    }

    &-Title {
        text-align: center;
    }
}
