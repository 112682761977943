@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/a11y';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/font';
@import '../../style/abstract/cms';

.UniversityPage {
    div[data-content-type="row"] {
        margin-block-end: 20px;
        
        .pagebuilder-column-group {
            margin-block-end: 0;
            gap: 20px;
            row-gap: 0;

            .pagebuilder-column {
                .BannerFullwidth {
                    padding-block-end: 25px;

                    h1 {                
                        @include font(24, 39, 600);
                    }
                    
                    p {
                        @include font(20, 25);
                    }

                    @include mobile {
                        h1 {                
                            @include font(18, 23, 600);
                        }
                        
                        p {
                            @include font(14, 21);
                        }
                    }
                }
            }
        }

        .full-height [data-content-type="html"] {
            .BannerFullwidth {
                height: 820px;
                min-height: unset;
                
                @include mobile {
                    height: 300px;
                    margin-block-end: 20px;
                }
            }
        }
    
        [data-content-type="html"] {
            .BannerFullwidth {
                height: 400px;
                min-height: unset;
                margin-block-end: 20px;
            }
            
            &:last-of-type {
                .BannerFullwidth {
                    margin-block-end: 0;
                    
                    @include mobile {
                        margin-block-end: 20px;
                    }
                }
            }
        }
        
        &:not(:first-of-type) {
            h1 {
                @include font(32, 40, 600, false, 1);
                
                font-family: $font-source-sans-pro;
            }
        }
        
        .BannerFullwidth {
            margin-block-end: 0;
            padding-block-end: 25px;
            
            @include mobile {
                height: 300px;
            }
            
            h1 {                
                @include font(24, 39, 600);
                
                @include mobile {
                    @include font(23, 28);
                }
            }
            
            p {
                @include font(20, 25, 600);
                
                @include mobile {
                    @include font(17, 21);
                }
            }

            p, h1 {
                transform: translateY(60px);
                width: calc(100% - 180px);

                @include mobile {
                    transform: none;
                }
            }
        }
        
        .Button {
            align-self: flex-end;
            
            @include narrow-mobile {
                margin-inline-start: 0;
                align-self: flex-start;
            }
        }
    }

    .CmsPage-Content > :first-child {
        @include mobile {
            height: 400px;
        }

        .BannerFullwidth {
            padding-block-end: 0px;
            
            @include mobile {
                height: 400px;

                h1 {
                    @include font(30, 37);
                }

                p {
                    @include font(18, 23);
                }
            }

            h1 {
                font-family: $font-libre-baskerville;
                
                @include font(60, 74, 700);

                @include mobile {
                    @include font(30, 37);
                }
            }
            
            p {
                @include font(24, 39, 600);

                @include mobile {
                    @include font(18, 23);
                }
            }
            
            h1, p {
                transform: none;
            }
        }
    }
}
