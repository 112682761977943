@import '../../../style/abstract/variables';
@import '../../../style/abstract/media';
@import '../../../style/abstract/a11y';
@import '../../../style/abstract/button';
@import '../../../style/abstract/loader';
@import '../../../style/abstract/icons';
@import '../../../style/abstract/parts';
@import '../../../style/abstract/font';
@import '../../../style/abstract/cms';

.Submenu {
    display: table;
    margin: auto;

    &-Links {
        display: inline-flex;
        overflow-x: scroll;
        max-width: $content-max-width;
        padding-block-end: 10px;
        width: fit-content;
        
        &::-webkit-scrollbar {
            width: 2px;
            height: 3px;
            
            &-button {
                display: none;
            }
            
            &-thumb {
                border-radius: 10px;
                background-color: $selection;
            }
        }

        @include mobile {
            overflow-x: scroll;
            overflow-y: hidden;
            max-width: calc(100vw - 40px);
            
            &::-webkit-scrollbar {
                display: none;
            }
        }
        
        .Button {
            margin-inline-end: 20px;

            &:last-of-type {
                margin-inline-end: 0;
            }
        }
    }
}
