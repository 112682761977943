@keyframes placeholderAnimation {
    from { background-position: -1200px 0; }

    to { background-position: 1200px 0; }
}

@keyframes placeholderAttrbuteAnimation {
    from {
        background-position: top 0 right var(--option-size);
    }

    to {
        background-position: bottom 0 left 0;
    }
}

@keyframes appear {
    from { opacity: 0; }

    to { opacity: 1; }
}

@keyframes disappear {
    from { opacity: 1; }

    to { opacity: 0; }
}

@keyframes rotate {
    from { transform: rotate(0); }

    to { transform: rotate(360deg); }
}

@keyframes slide-in {
    from { transform: translateX(100%); }

    to { transform: translateX(0); }
}

@keyframes slide-out {
    from { transform: translateX(0); }

    to { transform: translateX(100%); }
}
