@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/a11y';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/font';
@import '../../style/abstract/cms';

:root {
    --notification-info-background: var(--imported_notificationInfo_background_color, var(--primary-notification-background-color));
    --notification-error-background: var(--imported_notificationError_background_color, var(--primary-notification-background-color));
    --notification-success-background: var(--imported_notificationSuccess_background_color, var(--primary-notification-background-color));
}
