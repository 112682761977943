@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/a11y';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/font';
@import '../../style/abstract/cms';

.ExpandableContentShowMore {
    @include mobile {
        --option-size: 30px;
    }

    @include desktop {
        width: 255px;
    }
    
    &-ShowAllButton {
        display: flex;
        padding-inline: 20px;
        padding-block: 10px;
        width: 100%;
        
        >:not(:last-child) {
            float: inline-start;
        }
        
        svg {
            float: inline-end;
        }
    }
    
    &-FiltersNumber {
        padding-inline-start: 5px;
        color: $selection;
    }
    
    .Button {
        background-color: $white;
        display: block;
        
        &_isDisabled {
            opacity: 0.5;
            cursor: auto;
        }
    }
    
    &-Title {
        max-width: 195px;
        text-align: start;
        
        @include font(16, 20, 600, false, 1);
        
        &_isMobile {
            text-transform: uppercase;
        }
    }
    
    &-ExpandableChildren {
        margin-block-start: 10px;

        &_hasSublevel {
            margin-inline-start: 10px;

            > div {
                margin-block-start: 0px;
            }
        }
        
        @include mobile {
            display: flex;
            overflow-x: scroll;
            overflow-y: hidden;

            &_hasSublevel {
                display: block;

                > div:not(:first-of-type) {
                    margin-block-start: 20px;

                    > div {
                        margin-block-start: 10px;
                    }
                }
            }
            
            &::-webkit-scrollbar {
                display: none;
            }
            
            .ProductAttributeValue {    
                &-Color {
                    background-color: transparent;
                }
                
                &-Text {
                    min-width: max-content;
                    height: 30px;
                    margin-inline-end: 10px;
                    padding-inline: 10px;
                    background-color: $white;
                    justify-content: center;
                    align-items: center;
                    
                    .input-control {
                        display: none;
                    }
                    
                    &_isSelected {
                        background-color: $black;
                        color: $white;
                        
                        .ProductAttributeValue-SubLabel {
                            color: $default-secondary-dark-color;
                        }
                    }
                }
                
                &-Label {
                    text-transform: uppercase;
                }
            }
        }        
    }
}
