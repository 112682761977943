@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/a11y';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/font';
@import '../../style/abstract/cms';

:root {
    --search-overlay-width: 370px;
}

.SearchOverlay {
    &-Results {
        width: var(--search-overlay-width);

        @include desktop {
            box-shadow: 0 2px 5px rgba(100, 100, 100, 0.1);
            padding-block: 12px 0;
            padding-inline-start: 0;

            &_small {
                --search-overlay-width: 270px;
            }

            ul {
                max-height: 600px;
            }
        }

        @include ultra-narrow-desktop {
            width: calc(var(--search-overlay-width) + 49px);
        }
    }

    &-Title {
        letter-spacing: 1px;
        padding-inline-start: 20px;
        padding-block-end: 12px;
    }

    &-ViewAllButton {
        @include font(14, 18, 400, false, 1);

        text-transform: uppercase;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-block: 15px 20px;

        &:hover {
            text-decoration: underline;
        }

        .ChevronIcon {
            width: 12px;
            height: 12px;
            margin-inline-start: 20px;
        }
    }

    &-NoResults {
        height: var(--search-item-height);
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
