@import '../../../style/abstract/variables';
@import '../../../style/abstract/media';
@import '../../../style/abstract/a11y';
@import '../../../style/abstract/button';
@import '../../../style/abstract/loader';
@import '../../../style/abstract/icons';
@import '../../../style/abstract/parts';
@import '../../../style/abstract/font';
@import '../../../style/abstract/cms';

:root {
    --chevron-color: #{$default-primary-base-color};
}

.ChevronIcon {
    &_direction {
        &_left {
            transform: none;
        }

        &_right {
            transform: rotate(180deg);
        }

        &_bottom {
            transform: rotate(90deg);
        }

        &_top {
            transform: rotate(-90deg);
        }
    }
}

.ChevronIcon,
.ChevronDoubleIcon,
.ChevronThickIcon {
    path {
        fill: var(--chevron-color);
    }
}
