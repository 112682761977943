@import '../../../style/abstract/variables';
@import '../../../style/abstract/media';
@import '../../../style/abstract/a11y';
@import '../../../style/abstract/button';
@import '../../../style/abstract/loader';
@import '../../../style/abstract/icons';
@import '../../../style/abstract/parts';
@import '../../../style/abstract/font';
@import '../../../style/abstract/cms';

.EventList {
    margin-block-end: 100px;

    @include mobile {
        margin-block-end: 40px;
    }

    &-Grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        row-gap: 50px;
        column-gap: 20px;

        @include mobile {
            grid-template-columns: repeat(2, 1fr);
            column-gap: 10px;
            row-gap: 20px;
        }
    }

    &-Pager {
        display: flex;
        justify-content: flex-end;
        margin-block-end: 10px;
    }
}
