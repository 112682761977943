@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/a11y';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/font';
@import '../../style/abstract/cms';

.ContactForm {
    margin-block-end: 50px;

    .FieldForm {
        &-Fields {
            display: grid;
            grid-template-columns: repeat(2, 50%);
            gap: 0 10px;
            margin-block-end: 20px;

            @include mobile {
                grid-template-columns: 100%;
            }

            .Field {
                &-Wrapper {
                    grid-column: auto;
                }

                &_type {
                    &_textarea {
                        @include mobile {
                            order: -1;
                        }
                    }
                }
            }
        }

        &-Body {
            button {
                @include mobile {
                    --button-min-width: 100%;
                }
            }
        }
    }
}
