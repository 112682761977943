@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/a11y';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/font';
@import '../../style/abstract/cms';

.RetailerApplication {
    width: 100vw;
    height: 100vh;
    display: flex;
    position: fixed;
    inset-inline-start: 0;
    inset-block-start: 0;
    z-index: 799;
    background-color: $background;

    * {
        max-width: var(--content-wrapper-width);
        max-height: fit-content;
        margin: auto;
    }
}
