@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/a11y';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/font';
@import '../../style/abstract/cms';

.CloseIcon {
    cursor: pointer;

    path {
        transition: fill-opacity $rapid;
    }

    &:hover {
        path {
            fill-opacity: 0.4;
        }
    }
}
