@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/a11y';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/font';
@import '../../style/abstract/cms';

.LoaderNew {
    $size: 56px;

    &-Main {
        display: inline-block;
        position: relative;
        width: $size;
        height: $size;

        div {
            box-sizing: border-box;
            display: block;
            position: absolute;
            width: $size;
            height: $size;
            border: 7px solid $default-primary-base-color;
            border-radius: 50%;
            animation: rotate 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            border-color: $default-primary-base-color transparent transparent transparent;

            &:nth-child(1) {
                animation-delay: -0.35s;
            }

            &:nth-child(2) {
                animation-delay: -0.25s;
            }

            &:nth-child(3) {
                animation-delay: -0.125s;
            }
        }
    }
}
