@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/a11y';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/font';
@import '../../style/abstract/cms';

:root {
    --accordion-background: #{$default-secondary-base-color};
    --accordion-text-color: #{$default-primary-base-color};
    --accordion-min-width: 250px;
    --accordion-max-width: 250px;
}

.Accordion {
    $block: &;

    min-width: var(--accordion-min-width);
    max-width: var(--accordion-max-width);
    margin-block-end: 10px;

    @include mobile {
        --accordion-min-width: 120px;
        --accordion-max-width: 120px;
    }

    &_isSecondary {
        --accordion-background: #{$default-primary-base-color};
        --accordion-text-color: #{$default-secondary-base-color};
    }

    &_isOpen {
        #{$block} {
            &-Content {
                opacity: 1;
                max-height: 100%;
                padding: 10px 0;
            }
        }
    }

    &-Button {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: var(--accordion-background);
        border: none;
        padding: 10px 20px;
        font-family: $font-source-sans-pro;

        @include desktop {
            cursor: pointer;
        }
    }

    &-Title {
        @include font(16, 20, 600, var(--accordion-text-color), 1);
        @include truncate;
    }

    &-Content {
        opacity: 0;
        max-height: 0;
        overflow: hidden;
        transition: opacity $rapid;
    }

    .ChevronThickIcon {
        --chevron-color: var(--accordion-text-color);
    }
}
