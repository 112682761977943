@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/a11y';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/font';
@import '../../style/abstract/cms';

.ContactPage {
    @include mobile {
        padding-block: 20px 40px;
    }

    &-Heading {
        @include font(32, 40, 600);

        margin-block: 0 50px;
        height: auto;

        @include mobile {
            @include font(20, 25);

            margin-block-end: 20px;
        }
    }

    .Form {
        .Button {
            max-width: var(--button-min-width);
        }
    }

    div[data-content-type="column-group"] {
        @include mobile {
            flex-direction: column;
        }
    }
    
    div[data-content-type="column"] {
        /* stylelint-disable-next-line declaration-no-important */
        width: auto !important;

        &:first-of-type {
            margin-inline-end: 20%;

            @include mobile {
                margin-inline-end: 0;
                margin-block-end: 20px;
            }
        }

        h3 {
            margin-block-end: 20px;
        }
    
        p {
            opacity: 0.75;
        }
    }

    &-SuccessMessage {
        .Popup {
            &-Content {
                width: 750px;
                padding: 50px;
                
                > * {
                    max-width: fit-content;
                    margin: auto;
                }
                
                p {
                    text-align: center;
                }

                .Button {
                    margin-block-start: $gutter;
                    display: flex;
                }
            }
        }
    }
}
