@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/a11y';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/font';
@import '../../style/abstract/cms';

/* stylelint-disable declaration-no-important */

.RetailerApplicationConfirmation {
    display: grid;
    grid-template-columns: minmax(auto, 450px);
    padding-inline: 20px;

    * {
        margin-block-end: 20px !important;
    }

    p {
        max-width: 385px;
    }

    a, .Button {
        width: 100%;
        max-height: 60px;
    }
}
