/* stylelint-disable property-no-unknown */

:root {
    --cms-page-spacing: 50px;
}

@mixin image {
    display: block;
    margin-block-end: var(--cms-page-spacing);
    aspect-ratio: 12 / 5;
    object-fit: cover;
    object-position: center;

    @include mobile {
        margin-block-end: 10px;
    }

    @include mobile {
        aspect-ratio: unset;
        min-height: 400px;
    }
}

.pagebuilder {
    &-mobile {
        &-hidden {
            @include image;
            
            @include mobile {
                display: none;
            }
        }
        
        &-only {
            @include image;

            @include desktop {
                display: none;
            }
        }
    }

    &-column {
        &-group {
            gap: var(--cms-page-spacing);

            @include mobile {
                flex-direction: column;
                margin-block-end: 10px;
                gap: 10px;
            }

            :not(& > .RenderWhenVisible) {
                margin-block-end: var(--cms-page-spacing);
            }

            .RenderWhenVisible {                
                @include mobile {
                    padding-block-end: 0;
                }
            }
        }

        h2 {
            margin-block-end: 32px;

            @include mobile {
                margin-block-end: 10px;
            }
        }

        div[data-content-type="text"] > p {
            text-align: justify;
            margin-block-end: 20px;
        }
    }

    &-video {
        &-container {
            video {
                width: 100%;

                &::-webkit-media-controls {
                    &-timeline,
                    &-time-remaining-display,
                    &-current-time-display,
                    &-toggle-closed-captions-button {
                        display: none;
                    }

                    &-fullscreen-button {
                        @include mobile {
                            display: initial;
                        }
                    }
                }
            }
        }
    }

    &-button {
        &-primary,
        &-secondary {
            @include button;
        }

        &-primary {
            &:hover {
                background-color: $default-primary-light-color;
            }
        }

        &-secondary {
            background-color: $white;
            color: $black;

            &:hover {
                background-color: $default-secondary-dark-color;
            }
        }
    }
}

#html-body {
    div[data-content-type="row"] {
        &[data-appearance="contained"] {
            .contained {
                max-width: 750px;
                margin-inline: auto;
            }
        }
    }
    
    div[data-content-type="column"] {
        @include mobile {
            width: 100%;
        }

        &[data-appearance="full-height"] {
            div[data-content-type="html"],
            div[data-content-type="text"],
            .RenderWhenVisible {
                height: 100%;
            }
        }
    }
}
