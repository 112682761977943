@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/a11y';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/font';
@import '../../style/abstract/cms';

/* stylelint-disable declaration-no-important */

:root {
    --input-color: #{$text};
    --input-height: 60px;
    --input-padding: 20px 18px;

    @include mobile {
        --input-height: 40px;
        --input-padding: 10px 20px;
    }
}

.Field {
    margin-block-start: 0;
    width: 100%;
    max-width: 425px;

    &Group {
        &-Wrapper {
            margin-block-end: 20px;
        }
    }

    &-Wrapper {
        grid-column: 1/-1;

        &:not(:last-of-type) {
            margin-block-end: 20px;
        }
    }

    &, &-Wrapper {
        @include mobile {
            width: 100%;
            max-width: 100%;
        }
    }
    
    &-Label {
        padding-block-end: 15;
        letter-spacing: 1px;
    }
    
    &, &-Label, input, ::placeholder, select {
        @include font(12, 15, 400);
        
        text-transform: uppercase;
        font-family: $font-source-sans-pro;
    }

    &_hasError {
        input:not([type='number']),
        textarea,
        select {
            border: 1px solid var(--primary-error-color) !important;
        }
    }
    
    input {
        @include font(12, 20, 400, false, 1);
    
        height: var(--input-height);
        padding: var(--input-padding);
        border: none;

        &:disabled {
            color: lighten($text, 50%);
        }
    }

    textarea {
        @include font(12, 20, 400, false, 1);

        font-family: $font-source-sans-pro;
    }
    
    &-CheckboxLabel, &-RadioLabel {
        &_isDisabled {
            opacity: 0.5;
        }
    }
    
    &-RadioLabel {
        .input-control {
            @include mobile {
                min-width: 19px;
                min-height: 19px;
            }
        }
    }
    
    &-CheckboxLabel {
        .input-control {
            @include mobile {
                min-width: 15px;
                min-height: 15px;
            }
        }
    }

    &_type {
        &_text,
        &_textarea,
        &_email,
        &_password,
        &_date,
        &_time,
        &_datetime,
        &_datetime-local,
        &_file,
        &_select {
            &.Field_isValid {
                input,
                textarea,
                select {
                    &:placeholder-shown {
                        border: none;
                    }

                    border: none;
                }
            }
        }

        &_number {
            width: auto;
            
            [type='number'] {
                @include font(16, 25, 400);
                
                height: 47px;
                border: 1px solid $selection;
                width: 55px;
                text-align: center;

                @include mobile {                
                    width: 33px;
                    height: 26px;
                    padding: 0;
                }
            }
            
            button {
                width: 30px;
                height: 30px;
                border: none;
                margin: 0;
                margin-block-start: 0;
            }            
        }
        
        &_textarea {
            textarea {
                height: 200px;
                max-width: 100%;
                padding: 5px 10px;
                border: none; 
                
                @include font(12, 20, 400);
            }
        }
        
        &_select {
            select {
                border: none;
                
                @include font(12, 20, 400); 
            }
        }

        &_checkbox {
            label {
                text-transform: none;
            }
        }
    }
    
    [type="email"], [type="password"] {
        text-transform: none;
    }

    [type='radio'],
    [type='checkbox'] {
        &:disabled {
            opacity: 0;
        }
    }
    
    [type='checkbox'] {
        + .input-control {
            height: 15px;
            width: 15px;
            border: none;
        }

        &:checked {
            + .input-control {
                background-color: $black;

                &::after {
                    --checkmark-color: #{$white};
                }
            }
        }

        &:focus-visible {
            + .input-control {
                outline: 1px solid $text;
                outline-offset: 2px;
            }
        }
    }
    
    [type='radio'] {
        + .input-control {
            height: 18px;
            width: 18px;
            border: 1px solid $black;
            
            &::after {
                box-shadow: var(--checkmark-color);
                border-radius: 50%;
                width: 12px;
                height: 12px;
                inset-inline-start: 14%;
                inset-block-start: 14%;
                transform: rotate(0deg), translate(-50%, -50%);
            }
        }

        &:checked {
            + .input-control {
                &::after {
                    inset-inline-start: 14%;
                    inset-block-start: 14%;
                    transform: rotate(0deg), translate(-50%, -50%)
                }
            }
        }

        &:focus-visible {
            + .input-control {
                outline: 1px solid $text;
                outline-offset: 2px;
            }
        }
    }

    [type='file'] {
        border: none;
        height: 200px;

        + label {
            border: none;
        }

        &:disabled {
            cursor: default;
        }
    }

    &-CheckboxLabel_isSwitchButton {
        margin-inline-start: 43px;  
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 1px;        

        @include mobile {
            font-size: 10px;
            line-height: 13px;
        }

        [type="checkbox"] {
            opacity: 0;
            width: 32px;
            height: 16px;
            margin: 0;
            padding: 0;
            background-color: rgba(0, 0, 0, 0.2);
            
            &:checked {
                + .input-control {
                    background-color: $black;
                    
                    &::after {
                        display: none;
                    }
                    
                    &::before {
                        -webkit-transform: translateX(20px);
                        -ms-transform: translateX(20px);
                        transform: translateX(20px);
                    }
                }
            }

            &:hover:not(:disabled) + .input-control {    
                &::after {
                    @include desktop {
                        --checkmark-color: transparent;
                    }
                }
            }
            
            & + .input-control {
                transform: translateX(-43px);
                position: absolute;
                cursor: pointer;
                inset: 0;
                background-color: rgba(0, 0, 0, 0.2) !important;
                -webkit-transition: .4s;
                transition: .4s;
                border-radius: 88px;
                height: 16px;
                width: 32px;
                
                &::before {
                    position: absolute;
                    content: "";
                    height: 16px;
                    width: 16px;
                    inset: 0;
                    border-radius: 50%;
                    background-color: $black;
                    -webkit-transition: .4s;
                    transition: .4s;
                }
            }
        }
    }
}
