@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/a11y';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/font';
@import '../../style/abstract/cms';

:root {
    --cms-page-spacing: 50px;
}

.CmsPage {
    padding-block-start: var(--cms-page-spacing);
    margin-block-end: 100px;
    
    @include mobile {
        padding-block-start: 0;
        margin-block-start: 0;
        margin-block-end: 38px;
    }

    &-Wrapper {
        margin-block-start: 0;
        padding-inline: var(--content-wrapper-padding-inline);
        max-width: calc(var(--content-wrapper-width) + (var(--content-wrapper-padding-inline) * 2));
    }

    div[data-content-type="row"] {
        margin-block-end: var(--cms-page-spacing);

        div[data-content-type="row"] {
            margin-block-end: 0;
        }
        
        @include mobile {
            margin-block-end: 20px;
        }
    }

    div[data-content-type="divider"] {
        [data-element="line"] {
            border: solid;
        }
    }

    .TextPlaceholder {
        margin-block-end: 0;
        display: block;

        @include mobile {
            margin-block-end: 0;
        }
    }

    .widget {
        overflow-x: visible;
    }

    p::after {
        content: "";
        display: inline-block;
        width: 0px;
    }
}
