@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/a11y';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/font';
@import '../../style/abstract/cms';

:root {
    --search-field-min-width: 230px;
    --search-icon-size: 15px;
}

.SearchField {
    margin: 0;

    @include desktop {
        width: auto;
        max-width: initial;
        min-width: var(--search-field-min-width);
    }

    @include mobile {
        --search-icon-size: 25px;

        margin-block-start: 20px;
    }

    &-Input {
        @include font(12, 15, 400);

        height: 32px;
        border: none;
        background-color: $light-grey;

        @include mobile {
            @include font(14, 18, 600, false, 1);

            border-radius: unset;
            font-family: $font-source-sans-pro;
            padding-block: 4px;
            padding-inline: 0;
            background-color: transparent;
            border-bottom: 1px solid #000;

            &::placeholder {
                color: $selection;
            }
        }

        &:focus {
            border: none;

            @include mobile {
                border-bottom: 1px solid #000;
            }
        }
    }

    &-CloseIcon,
    &-SearchIcon {
        position: absolute;
        height: var(--search-icon-size);
        width: var(--search-icon-size);
        inset-inline-end: 10px;
        inset-block-start: 50%;
        transform: translateY(-50%);
        display: flex;
    }

    .CloseIcon,
    .SearchIcon {
        width: var(--search-icon-size);
        height: var(--search-icon-size);
    }
}
