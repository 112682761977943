@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/a11y';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/font';
@import '../../style/abstract/cms';

.PasswordChangePage {
    display: grid;
    grid-template-columns: minmax(auto, 450px);
    padding-inline: 20px;
    padding-block: 0;

    &-Wrapper, button {
        width: 100%;
    }

    h1 {
        font-family: $font-source-sans-pro;
        padding-block-end: 20px;
        text-align: center;

        @include font(24, 30, 600)
    }

    input {
        max-width: 450px;
    }

    button {
        margin-block-start: 35px;
    }
}
