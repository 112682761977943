@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/a11y';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/font';
@import '../../style/abstract/cms';

.BarGraph {
    background-color: $white;
    padding-block: 35px;
    padding-inline: 20px;

    g {
        &:first-of-type {
            * {
                fill-opacity: 1
            }
        }
    }

    &-Label, &-BarLabel {
        cursor: default;
    }
}
