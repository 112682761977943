
/** Colors **/


$white: #fff;
$black: #2A2D2F;
$light-grey: #F4F4F4;
$background: #FAFAFB;
$blue: #0089C4;
$green: #45BE70;
$maximum-red: #D71920;

$selection: rgba(0, 0, 0, 0.2);

// Override base scandipwa

$default-primary-base-color: $black;
$default-primary-dark-color: darken($default-primary-base-color, 15%);
$default-primary-light-color: lighten($default-primary-base-color, 15%);
$default-secondary-base-color: $white;
$default-secondary-dark-color: darken($default-secondary-base-color, 15%);
$default-secondary-light-color: lighten($default-secondary-base-color, 15%);

/** Typography **/

$baseFontSize: 16;
$text: $black;
$font-source-sans-pro: 'Source Sans Pro', sans-serif;
$font-libre-baskerville: 'Libre Baskerville', serif;

/** Sizing  **/

$content-max-width: 1200px;

/** Transitions **/

$rapid: 200ms;
$fast: 400ms;
$slow: 1000ms;

/** Custom **/

// Mostly for mobile users UX
$button-min-size: 45px;
$gutter: 20px;
