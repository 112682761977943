/* stylelint-disable declaration-no-important */

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@600;700&display=swap');

html {
    font-size: 100%;
}

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    font-family: $font-source-sans-pro;
    color: $text;
}

p {
    @include font(16, 25, 400, false, 1);
}

h1,
h2,
h3,
h4,
h5 {
    text-transform: uppercase;
}

h1 {
    @include font(60, 75, 700, false, 1);

    font-family: $font-libre-baskerville;

    @include mobile {
        @include font(30, 37);
    }
}

h2 {
    @include font(32, 40, 600, false, 1);

    @include mobile {
        @include font(20, 25);
    }
}

h3 {
    @include font(24, 30, 600, false, 1);

    @include mobile {
        @include font(18, 23);
    }
}

h4 {
    @include font(20, 25, 600, false, 1);

    @include mobile {
        @include font(14, 18);
    }
}

h5 {
    @include font(16, 20, 600, false, 1);
}

a {
    @include font(16, 20, 600, $black, 1);
    
    text-decoration: underline;
}

blockquote {
    border-left: none !important;
    padding-inline-start: 0px !important;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mobile {
        padding-block-start: 30px !important;
    }
    
    * {
        font-family: $font-source-sans-pro;
        max-block-size: inherit;
        
        @include font(25, 40, 800, $black, 1);
    
        @include mobile {
            @include font(20, 35, 700, $black, 1);
        }
    }

    & > :first-child::before {
        content: open-quote '';
        margin-block-start: -60px;

        @include font(72, 0, 700, $black, 1);

        @include mobile {
            @include font(55, 0, 700, $black, 1);
        }
    }
}
