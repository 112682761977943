:root {
    // Colors
    --color-black: #{$black};
    --color-white: #{$white};
    --color-gray: #{$light-grey};
    --color-dark-gray: #{light-grey};
    --primary-divider-color: var(--color-gray);
    --primary-error-color: #{$maximum-red};
    --secondary-error-color: var(--color-white);
    --primary-success-color: #{$green};
    --secondary-success-color: var(--color-white);
    --primary-info-color: #ffec51;
    --secondary-info-color: var(--color-white);
    --body-content-color: var(--color-black);
    --primary-notification-background-color: #{$default-primary-light-color};

    // Placeholders
    --placeholder-gradient-color: #d9d9d9;
    --placeholder-image: linear-gradient(to right, #ebebeb 0, var(--placeholder-gradient-color) 15%, #ebebeb 30%);
    --placeholder-size: 1200px 100%;
    --placeholder-animation: placeholderAnimation 2s linear infinite;

    --shake-animation: shake 820ms .5ms cubic-bezier(.36, .07, .19, .97) both;

    // Declare imported primary colors to change the theme's colors
    // "imported_..." variables are set up in Admin panel -> Stores -> Configuration -> Scandipwa -> Color Configuration
    // They have higher priority than "default_..." variables defined in code.
    --primary-base-color: #{$default-primary-base-color};
    --primary-dark-color: #{$default-primary-dark-color};
    --primary-light-color: #{$default-primary-light-color};
    --secondary-base-color: #{$default-secondary-base-color};
    --secondary-dark-color: #{$default-secondary-dark-color};
    --secondary-light-color: #{$default-secondary-light-color};
}
