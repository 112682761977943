@import '../../../style/abstract/variables';
@import '../../../style/abstract/media';
@import '../../../style/abstract/a11y';
@import '../../../style/abstract/button';
@import '../../../style/abstract/loader';
@import '../../../style/abstract/icons';
@import '../../../style/abstract/parts';
@import '../../../style/abstract/font';
@import '../../../style/abstract/cms';

.BannerBrand {
    display: flex;
    align-items: center;
    gap: 20px;

    @include mobile {
        display: grid;
        grid-template-columns: 1fr;
    }
    
    @include narrow-mobile {
        grid-auto-columns: 100%;
    }

    &-Banner {
        flex: 1;
        transition: opacity $rapid;
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;

        span {
            display: block;
            padding-block-end: 129.31%;
            
            @include mobile {
                padding-block-end: 114%;
            }
        }

        @include desktop {
            &:hover {
                opacity: 0.8;
            }
        }
    }
}
