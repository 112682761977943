@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/a11y';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';
@import '../../../../../src/style/abstract/font';
@import '../../../../../src/style/abstract/cms';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 .BlogRelatedPosts {
    &-TitleWrapper {
        height: fit-content;
        margin-bottom: 21px;
    }

    &-Title {
        width: fit-content;
        margin-bottom: 0;
    }

    &-Wrapper {
        margin: 0;
    }

    &-PostsWrapper {
        display: grid;
        grid-gap: 40px 20px;
        grid-template-rows: repeat(auto-fill, 1fr);
        grid-template-columns: repeat(4, 1fr);

        @include desktop {
            grid-gap: 20px 20px;
        }

        @include mobile {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 50px 20px;
        }
    }
}
