@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/a11y';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/font';
@import '../../style/abstract/cms';

/* stylelint-disable declaration-no-important */

.RetailerApplicationRegistration {
    $block: &;

    display: grid;
    grid-template-rows: 35% auto;
    row-gap: 50px;
    
    .Image, .Image-Image {
        height: 70px;
        width: 331px;
        padding: 0;
        margin-block-end: 0;
    }

    &-ContentWrapper {
        padding-inline: 50px;
        display: grid;
        grid-template-columns: repeat(2, minmax(auto, 475px));
        gap: 77px;
    }

    &-Welcome {
        h2 {
            text-align: center;
        }
    }

    &-WelcomeMessage {
        max-width: 406px;
        margin-inline-start: 0;
        margin-block-start: 32px !important;

        @include font(16, 25, 400);

        p {
            margin-block-end: 20px;
        }
    }

    &-CompanyName {
        @include font(16, 26, 600);

        font-family: $font-source-sans-pro;
    }

    &-Login {
        margin-inline-start: 0;
        width: 100%;

        h3 {
            padding-block-end: 29px;
        }

        .Field-Wrapper {
            label {
                margin-inline-start: 0;
                padding-block-end: 10px;
            }

            input {
                width: 100%;
                margin-block-end: 14px;
            }
        }

        button {
            width: 100%;
        }
    }
}
