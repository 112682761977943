@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/a11y';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/font';
@import '../../style/abstract/cms';

.Overlay {
    &.Footer-Overlay_isVisible {
        @include mobile {  
            inset-block-start: 55%;
            height: max-content;
            padding-block-start: 5%;
            padding-block-end: 10%;
        }
    }
}
