@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/a11y';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/font';
@import '../../style/abstract/cms';

.MenuPage {
    @include mobile {
        padding-inline: 30px;
        margin-block-start: 25px;
        animation: appear $fast forwards;

        &_isClosing {
            animation: disappear $rapid forwards ease-out;
        }

        &-CloseIcon {
            &Wrapper {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: $button-min-size;
                height: $button-min-size;
                margin-inline-start: auto;
            }
        }
    }
}
