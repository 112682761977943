@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/a11y';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/font';
@import '../../style/abstract/cms';

.StyleGuidePage {
    &-PopupControls {
        max-width: var(--content-wrapper-width);
        margin: 0 auto;
        display: flex;
        justify-content: flex-end;
        gap: 20px;
        margin-block-end: 20px;

        .Button {
            margin-inline-end: 0;
        }
    }

    &-Overlay {
        inset-block-start: 50px;
    }

    &-ProductCards {
        display: grid;
        gap: 18px;
        grid-template-columns: repeat(auto-fit, minmax(285px, 1fr));
    }

    .Button {
        margin-inline-end: 0;
    }

    .Pagination {
        margin: 0 auto;
    }
}
