@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/a11y';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/font';
@import '../../style/abstract/cms';

:root {
    --search-item-height: 70px;

    @include mobile {
        --search-item-height: 100px;
    }
}

.SearchItem {
    margin: 0;

    @include mobile {
        height: var(--search-item-height);
        
        &:not(:last-of-type) {
            margin-block-end: 10px;
        }
    }

    &-Wrapper {
        display: flex;
        overflow-x: hidden;

        @include mobile {
            display: grid;
            grid-template-columns: 90px minmax(0, 1fr) auto;
            align-items: center;
            gap: 20px;
        }
    }
    
    &-Title {
        @include font(14, 18, 400, false, 1);

        height: var(--search-item-height);

        &_isLoaded {
            display: flex;
            align-items: center; 
        }
    }
    
    &-Link {
        text-decoration: none;
        padding-inline: 20px;
        display: block;
        transition: background-color $rapid;
        will-change: background-color;

        @include mobile {
            background-color: $default-secondary-base-color;
        }
        
        &:hover {
            @include desktop {
                background-color: $selection;
            }
        }
    }

    .TextPlaceholder {
        height: inherit;
        display: flex;
        align-items: center;

        &::after {
            display: block;
            height: calc(var(--search-item-height) - 20px);
        }
    }

    .Image {
        height: var(--search-item-height);
        padding-block-end: var(--search-item-height);

        &_isPlaceholder {
            height: calc(var(--search-item-height) - 20px);
            padding-block-end: calc(var(--search-item-height) - 20px);
        }
    }
}
