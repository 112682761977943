* {
    position: relative;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    outline: 0;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
label,
summary {
    display: block;
}

main {
    --border-width: 1px;

    min-height: calc(100vh - var(--header-height));
    max-width: 100vw;
    padding-block-start: 50px;

    @include mobile {
        min-height: calc(100vh - var(--header-total-height) - var(--navigation-tabs-height) + 2 * var(--border-width));
        margin-block-end: 40px;
        padding-block-start: 0px;
    }
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;
    margin-block-end: 14px;

    @include mobile {
        margin-block-end: 17px;
    }
}

img {
    width: 100%;
}

body {
    scroll-behavior: smooth;
    background-color: $background;
}

li {
    list-style-type: none;
    list-style-position: inside;
    margin-block-end: 6px;

    // override default blue color for lists in Safari since iOS15

    * {
        color: var(--color-black);
    }

    ol & {
        counter-increment: list;

        @include mobile {
            counter-increment: list;
        }
    }

    &:last-child {
        margin-block-end: 20px;
    }
}

input,
select {
    min-height: 24px;
    min-width: 48px;
    font-size: 14px;

    @include mobile {
        min-height: 28px;
        min-width: 56px;
        font-size: 14px;
    }
}

textarea {
    resize: none;

    @include mobile {
        font-size: 16px;
        width: 100%;
    }
}

mark {
    margin-inline-end: 5px;
}

iframe {
    max-width: 100%;
}

a,
input,
button,
textarea,
select,
details {
    @include mobile {
        touch-action: manipulation;
    }
}

a,
button,
select {
    @include focus-outline;
}
