@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/a11y';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/font';
@import '../../style/abstract/cms';

.YearSelector {
    .Field-Wrapper {
        @include mobile {
            width: fit-content;
        }
    }

    .FieldSelect-Select {
        height: auto;
        padding: 12px 54px 12px 20px;
    }
}
