@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/a11y';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/font';
@import '../../style/abstract/cms';

:root {
    --header-nav-height: 105px;
    --header-gutter: 15px;
    --header-content-width: calc(#{$content-max-width} + (var(--header-gutter) * 2));
    --header-icon-clickable-size: 24px;

    @include desktop {
        --header-top-menu-height: 40px;
    }
    
    @include mobile {
        --header-top-menu-height: 40px;
        --header-nav-height: 80px;
        /* stylelint-disable-next-line declaration-no-important */
        --header-logo-height: 40px !important;
        --header-height: var(--header-nav-height);
    }
}


.Header {
    @include mobile {
        position: relative;
        background-color: $background;
        border-bottom: none;
        z-index: 1;
    }

    > * {
        max-width: 100%;
    }

    &-Wrapper {
        @include mobile {
            margin-block-end: 0;
        }
    }

    &-Nav {
        max-width: var(--header-content-width);
        display: block;
        padding-inline: var(--header-gutter);
    }

    &-Navigation {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;

        @include mobile {
            height: 100%;
        }

        &_isCustomerView {
            > * {
                flex: 1;
            }
        }
    }

    &-LogoWrapper {
        opacity: 1;
        max-width: 100%;
        
        @include desktop {
            margin-inline-end: auto;
            min-height: var(--header-nav-height);
            position: relative;
        }

        .Image {
            max-width: var(--header-logo-width);
            background: transparent;
        }
    }

    &-Actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-inline-start: auto;
    }

    &-IconsWrapper {
        gap: 25px;
        padding-inline-start: 20px;
    }

    &-Icon {
        min-width: var(--header-icon-clickable-size);
        min-height: var(--header-icon-clickable-size);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &_showOnTablet {
            display: none;

            @include ultra-narrow-desktop {
                display: flex;
            }
        }

        svg {
            width: 15px;
            height: 15px;
        }

        .UserIcon {
            width: 18px;
            height: 18px;
        }

        .HeartIcon {
            &:hover {
                fill: transparent;
                stroke: $black;
            }
        }
    }

    &-MyAccountContainer {
        margin-inline-start: 0;
        width: 100%;
        height: 100%;
        display: block;
    }

    &-MyAccountWrapper,
    &-MinicartButtonWrapper,
    &-BurgerButtonWrapper {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    &-MinicartItemCount {
        @include font(9, 12, 600, $text);

        min-width: auto;
        width: 13px;
        height: 13px;
        background-color: #ccc;

        @include desktop {
            inset-block: auto -5px;
            inset-inline-end: -5px;
        }
    }

    &-Button {
        &_type {
            &_minicart {
                margin-inline-start: 0;
            }
        }
    }
}
