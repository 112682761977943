@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/a11y';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';
@import '../../../../../src/style/abstract/font';
@import '../../../../../src/style/abstract/cms';

.StoreLocator {
    &-Heading {
        @include font(32, 40, 600, false, 1);

        font-family: $font-source-sans-pro;
        margin-block-end: 50px;

        @include mobile {
            @include font(20, 25);
        }
    }

    &-StoresWrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 40px;
        margin-block-start: 50px;
        padding-block-end: 105px;

        @include mobile {
            grid-template-columns: 1fr;
        }
    }

    &-SearchFilter {
        display: flex;
        gap: 20px;

        @include mobile {
            flex-direction: column-reverse;
        }

        .Field {
            max-width: unset;

            input {
                width: 100%;
            }

            &_type {
                &_text {
                    margin-inline-end: 0;
                }
            }

            &-Wrapper {
                &_type {
                    &_text {
                        width: calc(66% - 10px);
                        margin-inline-end: 0;
                    }

                    &_select {
                        width: calc(33% - 10px);
                    }

                    @include mobile {
                        &_text {
                            width: 100%;
                            margin-inline-end: 0;
                        }

                        &_select {
                            width: 50%;
                        }

                    }
                }
            }
        }
    }

    &-StoreList {
        max-height: 460px;
        overflow-y: scroll;
        padding-inline-end: 10px;

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-track {
            background-color: $white;
            border-radius: 0;
        }

        &::-webkit-scrollbar-thumb {
            height: 10px;
            border-radius: 10px;
            background-color: $black;
        }
    }

    &-Store {
        display: flex;
        padding: 10px 20px;
        background-color: $white;
        align-items: center;
        flex-wrap: wrap;
        margin-block-end: 10px;

        &Info {
            max-width: calc(100% - 50px);
            
            p {
                &:first-of-type {
                    @include font(16, 20, 600);
                }

                &:last-of-type {
                    @include font(14, 18, 400, $selection);
                }
            }
        }
    }

    &-MapIcon {
        margin-inline-end: 20px;
    }

    &-ChevronIcon {
        margin-inline-start: auto;

        svg {
            transform: unset;
        }
    }

    &-Brands {
        width: 100%;
        padding-inline-start: 36px;
        padding-block-start: 10px;
        height: 0;
        overflow: hidden;
        transition: height ease $rapid;

        &_isExpanded {
            height: 85px;
        }

        p {
            @include font(14, 18, 400);

            &:first-of-type {
                    color: $selection;
            }
        }
    }
}
