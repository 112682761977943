@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/a11y';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/font';
@import '../../style/abstract/cms';

:root {
    --menu-item-height: auto;
    --menu-total-height: 0px;
}

.Menu {
    $block: &;

    &-MenuWrapper {
        @include desktop {
            margin-block-start: 0;
        }

        @include ultra-narrow-desktop {
            display: none;
        }
    }

    &-ItemList {
        @include desktop {
            align-items: center;
        }
    }

    &-Item {
        margin-inline-end: 10px;
        cursor: pointer;

        @include ultra-narrow-desktop {
            margin-inline-end: 20px;
        }

        @include mobile {
            margin-block-end: 0;
        }

        &:last-child {
            margin-block-end: 0;
        }
        
        #{$block} {
            &-NoLink {
                display: flex; 
                
                @include ultra-narrow-desktop {
                    display: contents;
                }
                
                @include mobile {
                    margin-block: 8px;
                    display: contents;
                }
            }
        }
    }
    

    &-Link {
        padding-block: 10px;

        @include ultra-narrow-desktop {
            padding-block: 0;
        }
    }

    &-Link, &-ItemCaption, &-SubCatLink {
        @include font(12, 15, 600, false, 2);

        display: flex;
        align-items: center;
        text-transform: uppercase;
        font-family: $font-source-sans-pro;
        text-decoration: none;
        margin-inline-end: 0;
        justify-content: flex-start;

        @include ultra-narrow-desktop {
            justify-content: flex-end;
        }

        @include mobile {
            justify-content: flex-end;
        }

        &_noLink {
            cursor: default;
        }
    }

    &-MainCategories {
        @include desktop {
            .Menu-Link {
                font-weight: 600;
                margin-block: 0;
                margin-inline: 0;
            }
        }
    }

    &-ItemCaption {
        white-space: nowrap;

        &_type {
            &_main {
                @include font(12, 15, 600, false, 2);

                margin: 0;

                @include mobile {
                    @include font(32, 40, 600, false, 1);

                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                }
            }

            &_subcategory {
                @include font(12, 15, 600, false, 2);

                margin-block-start: 8px;

                @include mobile {
                    @include font(24, 30, 600, false, 1);

                    margin: 0;
                }
            }
        }
    }

    &-ExpandedState {
        float: initial;
        inset-inline-end: initial;
        display: flex;
        align-items: center;
        position: static;
        margin-inline-start: 20px;
        height: 35px;

        @include ultra-narrow-desktop {
            margin-inline-start: 0;
        }


        .ChevronThickIcon {
            width: 6px;
            height: 6px;
            margin-inline-start: 7px;

            @include mobile {
                width: 15px;
                height: 15px;
            }
        }
    }

    &-Sub {
        &CategoriesWrapper {
            position: absolute;
            background-color: $default-secondary-base-color;
            inset-inline-start: -10px;
            padding-block-start: 10px;
        }

        &Categories {
            @include desktop {
                padding: 0;
            }
        }

        &Menu {
            margin-block-end: 0;

            @include ultra-narrow-desktop {
                margin-block: 10px;
            }

            @include mobile {
                background: transparent;
                animation: appear $fast forwards;
            }

            &_isVisible {
                padding-inline: 0;
            }

            > .Menu-ItemList {
                @include desktop {
                    columns: initial;
                }
            }
        }

        &ItemWrapper {
            @include mobile {
                &:not(:last-of-type) {
                    margin-block-end: 5px;
                }
            }

            .Menu-Link {
                padding: 8px 10px;
                margin: 0;
                transition: background-color $rapid;
                cursor: pointer;

                @include mobile {
                    padding: 0;
                }

                @include desktop {
                    &:hover {
                        background-color: $selection;
                    }
                }
            }
        }
    }
}
