@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/a11y';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/font';
@import '../../style/abstract/cms';

.NewsletterSubscription {
    &-Wrapper {
        background-color: $white;
        max-width: $content-max-width;
        margin-inline: auto;
        text-align: center;
        height: 349px;
        margin-block-start: 50px;
        margin-block-end: 88px;

        @include mobile {
            margin-inline: 20px;
            height: fit-content;
            margin-block-start: 38px;
            margin-block-end: 50px;
        }

        @include narrow-desktop {
            margin-inline: 10px;
        }

        @include tablet {
            margin-inline: 10px;
        }

        @include narrow-mobile {
            height: 100%;
        }
    
        h2 {
            text-transform: uppercase;
            padding-block-start: 50px;
            padding-block-end: 19px;

            @include mobile {
                padding-block: 20px;
            }
        }
    
        p {
            padding-block-end: 50px;

            @include mobile {
                padding-block-end: 40px;
            }
        }
        
        .Form {
            display: flex;
            align-items: center;
            justify-content: center;
    
            .Field {
                background-color: transparent;
                border-bottom: 1px solid $black;
                text-align: start;
                max-width: 460px;

                input, input::placeholder {
                    @include font(16, 25, 400);
                } 
                
                input {
                    width: 460px;
                    height: 30px;

                    @include mobile {
                        width: 300px;
                    }

                    @include ultra-narrow-mobile {
                        width: 100%;
                    }

                    &::placeholder {
                        text-transform: none;
                    }
                }
            }
    
            .Button {
                margin-block-end: 50px;
                margin-block-start: 20;
                width: var(--button-min-width);

                @include mobile {
                    margin-block-end: 20px;
                }
            }
        }
    }
}
