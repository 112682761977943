@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/a11y';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/font';
@import '../../style/abstract/cms';

@mixin widgetLink {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    background-color: $selection;
    border-radius: 50%;

    &:last-of-type {
        margin-inline-start: 10px;
    }
}

.PaginationLink {
    @include font(16, 25, 400);

    user-select: none;
    text-decoration: none;
    margin-inline-end: 0;
    padding: 5px;

    &:hover {
        color: $selection;

        .ChevronIcon,
        .ChevronDoubleIcon {
            path {
                fill-opacity: 0.2;
            }
        }
    }

    &_isArrow {
        padding: 10px;
    }

    &_isCurrent {
        color: $selection;
    }

    &_isWidget {
        @include widgetLink();

        cursor: pointer;
        transition: background-color $rapid;
        will-change: background-color;

        &:hover {
            background-color: rgba(0, 0, 0, 0.1);
        }
    }
}
