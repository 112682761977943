@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/a11y';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';
@import '../../../../../src/style/abstract/font';
@import '../../../../../src/style/abstract/cms';

.PostsListing {
    margin-block-end: 50px;

    @include mobile {
        margin-block-end: 40px;
    }
    
    &-Grid {
        display: grid;
        gap: 50px 20px;
        grid-template-columns: repeat(4, 1fr);
        margin-block-end: 20px;

        @include tablet {
            grid-template-columns: repeat(3, 1fr);
        }

        @include mobile {
            grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
        }
    }

    &-Content {
        width: 100%;
        margin-block-end: 20px;
        
        > * {
            margin-block-end: 30px;
        }
    }

    &-NoPosts {
        grid-column: 1 / -1 ;
        justify-self: center;
    }

    .Submenu-Links {
        padding: 0;
    }

    .ContentWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
