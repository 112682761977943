@import '../../../style/abstract/variables';
@import '../../../style/abstract/media';
@import '../../../style/abstract/a11y';
@import '../../../style/abstract/button';
@import '../../../style/abstract/loader';
@import '../../../style/abstract/icons';
@import '../../../style/abstract/parts';
@import '../../../style/abstract/font';
@import '../../../style/abstract/cms';

.BannerDouble {
    display: flex;
    gap: 20px;

    @include mobile {
        gap: 10px;
    }

    &-Banner {
        @include banner-fullwidth;
        --banner-height: 500px;

        flex: 1;

        @include mobile {
            --banner-height: 170px;
        }
    }

    &-Title {
        @include font(24, 30, 600, false, 1);

        margin-block-end: 20px;
        text-transform: uppercase;
        
        @include mobile {
            @include font(14, 17);

            margin-block-end: 10px;
        }
    }

    &-CTA {
        @include mobile {
            margin-inline-end: auto;
            text-align: center;
        }
    }
}
