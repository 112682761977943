button {
    background-color: transparent;

    /* override default blue color for lists in Safari since iOS15 */

    * {
        color: $black;
    }
}

.Button {
    @include button;

    &_secondary {
        --button-transition-background: #{$default-secondary-dark-color};

        background-color: $white;
        color: $black;


        &[disabled] {
            background-color: $white;
            opacity: 0.5;
        }
    }

    &_text {
        background-color: transparent;

        @include desktop {
            &:hover {
                background-color: $light-grey;

                &::before {
                    width: 0%;
                }
            }
        }
    }

    &_likeLink {
        background-color: transparent;
        color: $black;
        text-transform: none;
        min-width: auto;
        min-height: auto;

        @include desktop {
            &:hover {
                background-color: transparent;
                text-decoration: underline;

                &::before {
                    width: 0%;
                }
            }
        }
    }
}
