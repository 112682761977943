@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/a11y';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/font';
@import '../../style/abstract/cms';

.NoMatch {
    @include mobile {
        h1 {
            font-size: 100px;
        }
    }

    &-Button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }
}
