@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/a11y';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/font';
@import '../../style/abstract/cms';

.AccessDenied {
    h1 {
        font-family: $font-source-sans-pro;

        @include font(32, 40, 600, false, 1);

        @include mobile {
            @include font(20, 25);
        }
    }

    .MyAccountOverlay {
        min-width: 300px;

        @include mobile {
            padding-inline: 10%;
        }
    }
}
