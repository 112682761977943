@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/a11y';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/font';
@import '../../style/abstract/cms';

.FieldFile {
    position: relative;
    background-color: $selection;
    height: 179px;

    &_isDisabled {
        opacity: 0.3;
    }

    svg {
        position: absolute;
        inset-inline-start: calc(50% - (96px / 2));
        inset-block-start: calc(50% - (96px / 2));
    }

    &-File {
        &List {
            p {
                @include font (12, 20, 400, false, 1);

                text-transform: none;
            }
        }

        &Row {
            display: grid;
            grid-template-columns: max-content 1fr;
            column-gap: 15px;
            padding-block-end: 15px;
            
            img {
                height: 46px;
                width: 92px;
            }
        }
    }

    &-AllowedTypes {
        color: $selection;
    }

    &-ExtraText {
        padding-block-start: 10px;

        &_isDisabled {
            opacity: 0.3;
        }
    }

    &-AllowedTypes, &-ExtraText {
        text-transform: none;
        padding-block-end: 10px;
        cursor: default;
        
        @include font (12, 20, 400, false, 1);
    }

    &_hasError {
        border: solid 1px var(--primary-error-color);
    }
}
