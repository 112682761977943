@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/a11y';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/font';
@import '../../style/abstract/cms';

:root {
    --content-wrapper-width: #{$content-max-width};
    --content-wrapper-padding-inline: 10px;

    @include mobile {
        --content-wrapper-padding-inline: 20px;
    }
}

.ContentWrapper {
    max-width: calc(var(--content-wrapper-width) + (var(--content-wrapper-padding-inline) * 2));
    padding-inline: var(--content-wrapper-padding-inline);
    margin-inline: auto;
}
