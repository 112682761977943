@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/a11y';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/font';
@import '../../style/abstract/cms';

:root {
    --navigation-tabs-height: calc(var(--footer-nav-height) + env(safe-area-inset-bottom));
}

.NavigationTabs {
    @include mobile {
        border-top: none;
        box-shadow: 0px 4px 10px $selection;
    }

    &_isHidden {
        display: block;
    }

    &-Nav {
        padding-inline: $gutter;

        @include ultra-narrow-mobile {
            padding-inline: 5px;
        }
    }

    &-Button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;

        &_isActive {
            svg {
                /* stylelint-disable-next-line declaration-no-important */
                opacity: 1 !important;
            }

            .NavigationTabs-Label {
                color: $text;
            }
        }

        svg {
            width: 24px;
            height: 24px;
            opacity: 0.2;
            transition: opacity $rapid;
            will-change: opacity;
        }

        .HeartIcon {
            &:hover {
                fill: transparent;
            }
        }

        .Header-MinicartItemCount {
            @include mobile {
                @include font(9, 12, 600, $white);

                inset-block: 25% auto;
                inset-inline: calc(50% + 15px) auto;
                transform: translate(-50%, -50%);
                background-color: $text;
                width: 13px;
                height: 13px;
            }
        }
    }

    &-Label {
        @include font(12, 20, 600, $selection, 1);

        font-family: $font-source-sans-pro;
        margin-block-start: 4px;
        transition: color $rapid;
        will-change: color;
    }
}
