@import '../../../style/abstract/variables';
@import '../../../style/abstract/media';
@import '../../../style/abstract/a11y';
@import '../../../style/abstract/button';
@import '../../../style/abstract/loader';
@import '../../../style/abstract/icons';
@import '../../../style/abstract/parts';
@import '../../../style/abstract/font';
@import '../../../style/abstract/cms';

.EventCard {
    &-Image {
        &.Image-HeightFull {
            height: 0;
        }
    }

    &-Name {
        margin-block-start: 10px;
    }

    &-Date {
        margin-block-end: 10px;
        color: $selection;
    }

    &-ReadMore {
        text-transform: uppercase;
        text-decoration: none;
        margin-block-start: 10px;
        display: flex;
        align-items: center;

        &:hover {
            svg {
                transform: rotate(-90deg) translateY(10px);
            }
        }

        svg {
            transform: rotate(-90deg);
            margin-inline-start: 10px;
            transition: transform ease $rapid;
        }
    }

    &_isInPast {
        opacity: 0.2;
    }
}
