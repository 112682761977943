@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/a11y';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/font';
@import '../../style/abstract/cms';

.CarouselScroll {
    width: 100%;
    margin-block-start: 20px;

    &-ContentWrapper {
        width: 100%;
        display: block;
    }

    .CarouselScroll-Content {
        display: grid;
        transition: none;
        padding: 0;
    }
}
